import EarnDetailsPage from '@components/earn/EarnDetailsPage'
import EarnHomePage from '@components/earn/EarnHomePage'
import Modal from '@components/shared/Modal'
import { useCreatePoolStore } from '@store/permissionlessCreatePoolStore'
import SEO from 'components/Common/SEO'
import { ReferralCodeForm } from 'components/Referrals/JoinReferralCode'
import {
  Mode,
  Operation,
} from 'components/Synthetics/GmSwap/GmSwapBox/GmSwapBox'
import { useUserReferralCode } from 'domain/referrals'
import { useMarketsInfo } from 'domain/synthetics/markets'
import { useChainId } from 'gmx/lib/chains'
import { getPageTitle } from 'gmx/lib/legacy'
import useWallet from 'gmx/lib/wallets/useWallet'
import { isEmpty } from 'lodash'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { useAccount } from 'wagmi'

export async function getStaticProps({ locale }: { locale: string }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, [
        'account',
        'close-account',
        'common',
        'notifications',
        'onboarding',
        'onboarding-tours',
        'profile',
        'settings',
        'swap',
        'trade',
        'tv-chart',
        'errors',
        'pools',
      ])),
    },
  }
}

function Index({
  shouldDisableValidation,
}: {
  shouldDisableValidation?: boolean
}) {
  const { chainId } = useChainId()
  const router = useRouter()
  const query = router.query
  const { onPoolDetailsPage, setMarketsInfoDataStore, setOnPoolDetailsPage } =
    useCreatePoolStore()
  const { marketsInfoData = {}, tokensData, mutate } = useMarketsInfo()
  const { signer } = useWallet()
  const { address: account } = useAccount()
  const [showReferralModal, setShowReferralModal] = useState(false)
  const { userReferralCodeString } = useUserReferralCode(
    signer,
    chainId,
    account,
  )

  const [operation, setOperation] = useState<Operation>(Operation.Deposit)
  const [mode, setMode] = useState<Mode>(Mode.Single)

  useEffect(() => {
    if (query?.ref) {
      setShowReferralModal(true)
    }
  }, [query?.ref])

  useEffect(() => {
    if (isEmpty(marketsInfoData)) {
      return
    }
    setMarketsInfoDataStore({ marketsInfoData, mutate })
  }, [marketsInfoData, mutate, setMarketsInfoDataStore])

  useEffect(() => {
    if (operation === Operation.Withdrawal) {
      setMode(Mode.Pair)
    }
  }, [operation])

  useEffect(() => {
    return () => setOnPoolDetailsPage(false)
  }, [])

  return (
    <SEO title={getPageTitle('Earn')}>
      <div className="h-full w-full">
        {onPoolDetailsPage ? (
          <EarnDetailsPage
            marketsInfoData={marketsInfoData}
            shouldDisableValidation={!!shouldDisableValidation}
            tokensData={tokensData}
            operation={operation}
            setOperation={setOperation}
            mode={mode}
            setMode={setMode}
          />
        ) : (
          <EarnHomePage
            marketsInfoData={marketsInfoData}
            tokensData={tokensData}
            shouldDisableValidation={!!shouldDisableValidation}
            operation={operation}
            setOperation={setOperation}
            mode={mode}
            setMode={setMode}
          />
        )}
      </div>

      <Modal
        className="Connect-wallet-modal"
        isOpen={showReferralModal}
        onClose={() => setShowReferralModal(false)}
        title={`Join Referral Code`}
      >
        <div className="h-full pt-4">
          <ReferralCodeForm
            userReferralCodeString={userReferralCodeString || ''}
            defaultValue={(query?.ref as string) || ''}
            type="edit"
            callAfterSuccess={() => setShowReferralModal(false)}
          />
        </div>
      </Modal>
    </SEO>
  )
}

export default Index
