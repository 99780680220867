import { Dialog } from '@headlessui/react'
import mangoStore from '@store/mangoStore'
import clsx from 'clsx'
import Image from 'next/image'
import { ReactNode } from 'react'
import { IoMdClose } from 'react-icons/io'
import styles from './Modal.module.css'
import { CLOUD_FRONT_URL } from 'config/constants'
import { useViewport } from 'hooks/useViewport'

type ModalProps = {
  children: React.ReactNode
  disableOutsideClose?: boolean
  fullScreen?: boolean
  isOpen: boolean
  onClose?: () => void
  panelClassNames?: string
  hideClose?: boolean
  title?: string
  className?: string
  hasTitle?: boolean
  hasSubtitle?: boolean
  subTitle?: ReactNode
  hasImageAtBottom?: boolean
  isRegular?: boolean
  hasSpaceBtwCloseAndTitle?: boolean
}

function Modal({
  children,
  disableOutsideClose = false,
  fullScreen = false,
  isOpen,
  onClose,
  panelClassNames,
  hideClose,
  title,
  className,
  hasTitle = true,
  hasSubtitle = false,
  subTitle,
  hasImageAtBottom = false,
  isRegular = false,
  hasSpaceBtwCloseAndTitle = true,
}: ModalProps) {
  const themeData = mangoStore((s) => s.themeData)
  const { isMobile } = useViewport()

  const handleClose = () => {
    if (disableOutsideClose) {
      return
    }
    onClose ? onClose() : null
  }

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      className={`relative z-50 overflow-y-auto font-flexo ${className} text-sm`}
      title={title}
    >
      <div
        className={`fixed inset-0 bg-black/40 backdrop-blur-[5px] ${
          disableOutsideClose ? 'pointer-events-none' : ''
        }`}
        aria-hidden="true"
        // onClick={(e) => e.stopPropagation()}
      />
      <div
        className={`fixed inset-0 flex items-center justify-center ${
          fullScreen ? '' : 'sm:px-4'
        }`}
      >
        <Dialog.Panel
          className={`${themeData.fonts.body.variable} font-body ${
            fullScreen ? '' : !isRegular && styles.dialogueContainer
          } ${panelClassNames}`}
        >
          <div className={!isRegular ? styles.modalDarkBg : ''} />
          <div className={!isRegular ? styles.modalGlass : ''}>
            {!isRegular && (
              <div className="absolute z-[-1] h-full w-full">
                <Image
                  alt=""
                  src={CLOUD_FRONT_URL + '/images/bg-noise.png'}
                  layout="fill"
                  objectFit="cover"
                />
              </div>
            )}
            <div className={!isRegular ? styles.modalBgNoise : ''}>
              {hasTitle && (
                <div
                  className={clsx(
                    'border-b border-th-input-border pb-4',
                    isMobile ? 'mx-4 mt-4' : 'mx-6 mt-6',
                  )}
                >
                  <div
                    className={clsx(
                      'flex flex-col',
                      hasSpaceBtwCloseAndTitle ? 'space-y-4' : '',
                    )}
                  >
                    {!hideClose ? (
                      <button
                        onClick={onClose}
                        className={`self-end text-th-button focus:outline-none focus-visible:text-th-active sm:right-2 sm:top-2 md:hover:text-th-active`}
                      >
                        <IoMdClose fontSize={20} className="text-th-fgd-3" />
                      </button>
                    ) : null}
                    <Dialog.Title
                      as="h3"
                      className="text-th-fgd-1/90 text-base font-bold leading-8 sm:text-2xl sm:leading-6"
                    >
                      {title}
                    </Dialog.Title>
                  </div>
                  {hasSubtitle && (
                    <div className="text-sm font-normal leading-normal text-th-fgd-2">
                      {subTitle}
                    </div>
                  )}
                </div>
              )}

              <div
                className={clsx(
                  'h-full overflow-y-scroll',
                  isMobile ? 'mx-4 mb-4' : 'mx-6 mb-6',
                  hasImageAtBottom && '!mx-0 !mb-0',
                )}
              >
                {children}
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  )
}

export default Modal
