import { useCreatePoolStore } from '@store/permissionlessCreatePoolStore'
import { MarketsInfoData } from 'domain/synthetics/markets'
import { PoolDataType } from 'hooks/usePerpsPoolData'
import { useViewport } from 'hooks/useViewport'
import Image from 'next/image'
import EarnPoolSelector from './EarnPoolSelector'

export const PoolPageHeader = ({
  marketsInfoData,
  data,
}: {
  marketsInfoData: MarketsInfoData
  data: PoolDataType[]
}) => {
  const { marketDetailAddress: selectedPool } = useCreatePoolStore()
  const currentMarketInfo = Object.values(marketsInfoData).filter(
    (item) => item.marketTokenAddress === selectedPool,
  )[0]

  const { setOnPoolDetailsPage } = useCreatePoolStore()
  const { isMobile } = useViewport()

  return (
    <div className="relative flex h-14 items-center gap-2 border-b border-th-input-border px-4 sm:h-[60px] sm:px-6">
      {isMobile && (
        <div onClick={() => setOnPoolDetailsPage(false)}>
          <Image
            alt="arrow-down"
            width={24}
            height={24}
            src="/icons/arrow-down-grey.svg"
            className={'rotate-90'}
          />
        </div>
      )}
      <EarnPoolSelector selectedPool={currentMarketInfo} tableData={data} />
    </div>
  )
}
