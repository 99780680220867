import { Disclosure } from '@headlessui/react'
import clsx from 'clsx'
import Image from 'next/image'
import { ReactNode } from 'react'
import { cn } from 'utils/classnames'

interface TableRowAccordianProps {
  VisibleContent: string | ReactNode
  HiddenContent: string | ReactNode
  SubRowContent?: string | ReactNode
  handleOnClick?: () => void
  className?: string
  buttonClass?: string
  panelClass?: string
}

const TableRowAccordian = ({
  className,
  VisibleContent,
  HiddenContent,
  SubRowContent,
  handleOnClick,
  buttonClass,
  panelClass,
}: TableRowAccordianProps) => {
  return (
    <Disclosure>
      {({ open }) => (
        <div onClick={handleOnClick} className={className}>
          <div
            className={cn(
              'flex w-full items-center justify-between px-4',
              buttonClass,
            )}
          >
            <div className="flex-1">{VisibleContent}</div>

            <Disclosure.Button
              className="cursor-pointer"
              onClick={(e) => e.stopPropagation()}
            >
              <Image
                alt="arrow-down"
                width={16}
                height={16}
                src="/icons/arrow-down-grey.svg"
                className={clsx(open ? 'rotate-180' : 'rotate-0')}
              />
            </Disclosure.Button>
          </div>
          {Boolean(SubRowContent && !open) && (
            <div className="flex justify-end border-b border-th-input-border px-4">
              {SubRowContent}
            </div>
          )}
          <Disclosure.Panel className={cn('px-4', panelClass)}>
            {HiddenContent}
          </Disclosure.Panel>
        </div>
      )}
    </Disclosure>
  )
}

export default TableRowAccordian
